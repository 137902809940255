import { createSlice, current } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

import chatAxios from '../../utils/chat_axios';
import { getBaseUrl } from '../../utils/server_config';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const socketAxios = require('axios');

const initialState = {
  isLoading: false,
  hasMoreMessages: true,
  isLoadMore: false,
  error: null,
  participants: [],
  recipients: [],
  messages: [],
  otherUser: null,
  conversation: null,
  isTyping: false,
  supportConversation: null,
  page: 1,
  pageSize: 20,
  totalPage: 2,
};

const slice = createSlice({
  name: 'chat_details',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START LOADING
    startLoadMore(state) {
      state.isLoadMore = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET CONVERSATIONS
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      state.conversation = conversation;

    },
    // GET OTHER USER
    getOtherUserSuccess(state, action) {
      const otherUser = action.payload;

      if (otherUser !== undefined) {
        state.otherUser = otherUser
      } else {
        state.otherUser = null;
      }

    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      state.messages.push(newMessage);
    },
    setTyping(state, action) {
      const typing = action.payload;
      state.isTyping = typing;
      // const { conversationId } = action.payload;
      // const conversation = state.conversations.byId[conversationId];
      // if (conversation) {
      //   conversation.unreadCount = 0;
      // }  
    },
    markConversationAsReadSuccess(state, action) {
      // const { conversationId } = action.payload;
      // const conversation = state.conversations.byId[conversationId];
      // if (conversation) {
      //   conversation.unreadCount = 0;
      // }  
    },

    getMessagesSuccess(state, action) {
      const data = action.payload;
      state.messages = data.messages;
      state.totalPage = data.totalPage;
      state.page = 1;
      state.isLoading = false;
    },
    loadMoreMessages(state, action) {
      const data = action.payload;

      const newMessages = data.messages;
      const currentState = current(state)

      const messages = currentState.messages;
      // const page = data.page;
      const totalPage = data.totalPage;
      if (newMessages.length === 0 || state.page >= totalPage) {
        state.page = totalPage;
        state.totalPage = totalPage;
      } else {
        state.messages = [...newMessages, ...messages];
        state.totalPage = data.totalPage;
        state.page += 1;
      }
      state.isLoadMore = false;
    },
    addMessageSuccess(state, action) {
      const data = action.payload;
      const message = data.message;
      const currentState = current(state)
      const messages = currentState.messages;

      const isExist = messages.some(item => item.id === message.id);
      if (!isExist) {
        state.messages = [...messages, message];
      }
    },
    getSupportConversationSuccess(state, action) {
      const data = action.payload;
      const supportConversation = data.supportConversation;
      const conversation = data.conversation;
      if (conversation) {
        state.conversation = conversation;
      }
      state.supportConversation = supportConversation;
    }

  },
});

// Reducer
export default slice.reducer;

export const selectChatState = (state) => state.parent;


// Actions
export const { onSendMessage, setTyping } = slice.actions;


// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      await axios.get('v2/message/set-all-seen', {
        params: { conversation_id: conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getOtherUser(userId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`v1/user/get-one/${userId}`);
      const user = response.data
      dispatch(slice.actions.getOtherUserSuccess({ user }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function selectUser(user_id, onNavigate) {
  return async () => {

    dispatch(slice.actions.startLoading());

    try {
      const conversationListResponse = await axios.get(`v1/admin/support-conversation/open?user_id=${user_id}`);

      const supportConversation = conversationListResponse.data.data;

      const conversation = {
        'conversation_id': supportConversation.conversation_id,
        'user_id': supportConversation.support_user_id,
        'first_name': supportConversation.support_name,
        'avatar': supportConversation.support_avatar,
      };


      onNavigate(conversation);

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getMessageListByUserIds(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const page = slice.getInitialState().page

      const pageSize = slice.getInitialState().pageSize

      const queryParams = {
        'conversation_id': conversationId,
        'sort': '-created_at',
        'page': page,
        'page_size': pageSize,
      };

      const response = await axios.get(`v1/portal/message/get-list`, { params: queryParams });
      const data = response.data
      const messages = data.data;
      const meta = data.meta.metadata;
      const conversation = {
        'user_id': meta.id,
        'first_name': meta.name,
        'avatar': meta.avatar,
      };

      dispatch(slice.actions.getConversationSuccess(conversation));

      const userId = conversation.user_id;

      const userResponse = await axios.get(`v1/user/get-one/${userId}`);

      const user = userResponse.data.data;

      dispatch(slice.actions.getOtherUserSuccess({ user }));

      const totalPage = data.meta.total

      const reversedList = [...messages].reverse();

      dispatch(slice.actions.getMessagesSuccess({ 'messages': reversedList, 'totalPage': totalPage }));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

}
export function geActiveConversation(conversationId, allConversations) {
  return async () => {

    dispatch(slice.actions.startLoading());

    try {

      const page = slice.getInitialState().page

      const pageSize = slice.getInitialState().pageSize

      const queryParams = {
        'conversation_id': conversationId,
        'sort': '-created_at',
        'page': page,
        'page_size': pageSize,
      };

      const response = await axios.get(`v1/portal/message/get-list`, { params: queryParams });

      const data = response.data


      const messages = data.data;
      const meta = data.meta.metadata;

      const conversation = {
        'conversation_id': conversationId,
        'user_id': meta.id,
        'first_name': meta.name,
        'avatar': meta.avatar,
      };

      const totalPage = data.meta.total

      const reversedList = [...messages].reverse();

      dispatch(slice.actions.getConversationSuccess(conversation));

      const userId = conversation.user_id;

      const userResponse = await axios.get(`v1/user/get-one/${userId}`);

      const user = userResponse.data.data;

      dispatch(slice.actions.getOtherUserSuccess({ user }));

      const suportResponse = await axios.post('v1/support-conversation/open');

      const supportConversation = suportResponse.data.data;

      dispatch(slice.actions.getSupportConversationSuccess({ 'supportConversation': supportConversation }));


      dispatch(slice.actions.getMessagesSuccess({ 'messages': reversedList, 'conversationId': conversationId, 'totalPage': totalPage }));


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handleSocketEvent(socketEvent, conversation) {
  return async () => {

    try {
      const to = socketEvent.event_payload.to

      const from = socketEvent.event_payload.from
      // const conversation_id = socketEvent.event_payload.conversation_id

      if (socketEvent.event_name === 'chat' && conversation.user_id === from) {
        const newMessage = socketEvent.event_payload.body
        dispatch(slice.actions.addMessageSuccess({ message: newMessage }));

      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handleLoadMoreMessages(conversationId, page, pageSize) {
  return async () => {
    dispatch(slice.actions.startLoadMore());
    try {

      const queryParams = {
        'conversation_id': conversationId,
        'sort': '-created_at',
        'page': page + 1,
        'page_size': pageSize,
      };

      const response = await axios.get(`v1/portal/message/get-list`, { params: queryParams });

      const data = response.data

      const newMessages = data.data;

      const totalPage = data.meta.total_pages

      const newPage = data.meta.page


      dispatch(slice.actions.loadMoreMessages({
        'messages': newMessages,
        'conversationId': conversationId,
        'totalPage': totalPage,
        'page': newPage,
      }));


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

function getFileExtension(fileName) {
  // Use a regular expression to extract the file extension
  const regex = /(?:\.([^.]+))?$/;
  const extension = regex.exec(fileName)[1];
  return extension ? extension.toLowerCase() : null;
}


export function sendFile(file, bytes, conversation) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      console.log(conversation)

      const fileExtension = getFileExtension(file.name);

      const preuploadReponse = await axios.post(`v1/media/pre-upload`, { 'media_type': fileExtension })
      const push_url = preuploadReponse.data.data.push_url
      const mediaType = file.type

      // const uploadResponse = await fetch(push_url, {
      //   method: 'PUT',
      //   body: bytes,
      //   headers: {
      //     'Content-Type': mediaType,
      //     "x-amz-acl": 'public-read'
      //   },
      // })
      const config = {
        method: 'put',
        url: push_url,
        headers: {
          'content-type': mediaType,
          "x-amz-acl": 'public-read'
        },
        data: bytes
      };

      const uploadResponse = await chatAxios({
        url: push_url,
        method: "put",
        data: file,
        headers: {
          'content-type': mediaType,
          "x-amz-acl": 'public-read',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
        },
        maxContentLength: (100 * 1024 * 1024 * 1024),
        timeout: (30 * 60 * 1000),
        onUploadProgress: (pevt) => {
          console.log(`uploaded.:${Math.round((pevt.loaded / pevt.total) * 100)}`)
        }
      });


      const name = uploadResponse.data.data.name

      await chatAxios.post('v1/media/pos-upload', { data: { 'media_type': fileExtension, 'name': name, 'type_to_crop': 'chat' } })



    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function sendTyping(typing, supportConversation, conversation) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const accessToken = localStorage.getItem('accessToken');
      const requestBody = {
        'topic': typing ? 'is_typing' : 'stop_typing',
        'from': supportConversation.support_user_id,
        'to': conversation.user_id,
        'conversationId': conversation.conversation_id,
        'body': {}
      }

      const send_url = `${getBaseUrl()}/ws-gateway/send`;

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: send_url,
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        data: requestBody
      };

      await chatAxios(config)

      // chatAxios.post(send_url, config)
      // await chatAxios.get(send_url, {
      //   method: 'GET',
      //   body: JSON.stringify(requestBody),
      //   headers: {
      //     'content-type': 'application/json',
      //     'Authorization': `Bearer ${accessToken}`
      //   }
      // })

      // const options = {
      //   method: 'GET',
      //   url: send_url,
      //   headers: {
      //     'content-type': 'application/json',
      //     'Authorization': `Bearer ${accessToken}`
      //   },
      //   body: JSON.stringify(requestBody),
      // };
      // await fetch(send_url, options);
      // await fetch(send_url, {
      //   method: 'GET',
      //   headers: {
      //     'content-type': 'application/json',
      //     'Authorization': `Bearer ${accessToken}`
      //   },
      //   body: JSON.stringify(requestBody),
      // });
      // await chatAxios(config)
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function sendNewMessage(message, conversation, supportConversation, onSendMessageComplete) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      let newMessageFormat = null
      const currentDate = new Date();
      const utcISOString = currentDate.toISOString();

      if (message.contentType === 'text') {
        newMessageFormat = {
          'body': { 'content': message.message },
          'conversation_id': message.conversationId,
          'content': message.message,
          'id': message.messageId,
          'creator_id': message.senderId,
          'created_at': utcISOString,
          'type': 'text'
        }
      }


      const response = await axios.post(`v1/admin/message/create`, newMessageFormat);

      const newMessage = response.data.data

      onSendMessageComplete(newMessageFormat);

      dispatch(slice.actions.addMessageSuccess({ message: newMessage }));

      const requestBody = {
        'topic': 'chat',
        'from': supportConversation.support_user_id,
        'to': conversation.user_id,
        'body': newMessage
      }

      const accessToken = localStorage.getItem('accessToken');

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${getBaseUrl()}/ws-gateway/send`,
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        data: requestBody
      };


      await chatAxios(config)


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMessageList(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const page = slice.getInitialState().page
      const pageSize = slice.getInitialState().pageSize
      const queryParams = {
        'conversation_id': conversationId,
        'sort': '-created_at',
        'page': page,
        'page_size': pageSize,
      };

      const response = await axios.get(`v1/portal/message/get-list`, { params: queryParams })
      const data = response.data
      const messages = data.data
      const totalPage = data.meta.total_pages

      dispatch(slice.actions.getMessagesSuccess({ 'messages': messages, 'conversationId': conversationId, 'totalPage': totalPage }));


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

