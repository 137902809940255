import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  reportUsers: [],
  total: 0,
  reportUserHistory: [],
  totalHistory: 0,
};

const slice = createSlice({
  name: 'report_user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET reportUsers
    getReportUsersSuccess(state, action) {
      state.isLoading = false;
      state.reportUsers = action.payload.reportUsers;
      state.total = action.payload.total;
    },

    // GET reportUsers
    getReportUserHistorySuccess(state, action) {
      state.isLoading = false;
      state.reportUserHistory = action.payload.reportUserHistory;
      state.total = action.payload.total;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReportUsers({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/block-report/get-list-user', {
        params,
      });
      dispatch(
        slice.actions.getReportUsersSuccess({
          reportUsers: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReportUserHistory({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/block-report/get-list', {
        params,
      });
      dispatch(
        slice.actions.getReportUserHistorySuccess({
          reportUserHistory: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
